import { createRouter , createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component:() => import('../views/home/homeIndex.vue'),
    meta: {
      title: '主页',
    }
  },
  {
    path: '/life',
    name: 'life',
    component:() => import('../views/life/lifeIndex.vue'),
    meta: {
      title: '生活',
    }
  },
  {
    path: '/shouyi',
    name: 'shouyi',
    component:() => import('../views/life/shouYiIndex.vue'),
    meta: {
      title: '收益',
    }
  },
  {
    path: '/program',
    name: 'program',
    component:() => import('../views/program/programIndex.vue'),
    meta: {
      title: '编程',
    }
  },
  {
    path: '/json',
    name: 'json',
    component:() => import('../views/program/jsonIndex.vue'),
    meta: {
      title: 'Json',
    }
  },
  {
    path: '/jsonShow',
    name: 'jsonShow',
    component:() => import('../views/program/jsonShowIndex.vue'),
    meta: {
      title: 'JsonShow',
    }
  },
  {
    path: '/jsonView',
    name: 'jsonView',
    component:() => import('../views/program/jsonViewIndex.vue'),
    meta: {
      title: 'JsonView',
    }
  },
  {
    path: '/guid',
    name: 'guid',
    component:() => import('../views/program/guidIndex.vue'),
    meta: {
      title: 'guid',
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
